import React, { Fragment } from 'react';

import { faFaceFrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Table } from 'react-bootstrap';

import metadata from '../metadata.json';

const SSO_APP_NAME = process.env.REACT_APP_SSO_APP_NAME;
const ENV_APP_NAME = process.env.REACT_APP_ENV_APP_NAME;

const { version: VERSION, commit: HASH } = metadata;
const CURRENT_VERSION_NUMBER = `${VERSION.major}.${VERSION.minor}.${VERSION.revision}-${VERSION.buildnumber}`;

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
    }

    buttons = (
        <Fragment>
            <Button
                name="reset"
                onClick={() => {
                    window.location.reload();
                }}
                className="w-25 me-2 rounded-0"
                variant="dark">
                Restart app and stay on same page.
            </Button>
            <Button
                name="reset"
                onClick={() => {
                    window.location.href = '/';
                }}
                className="w-25 ms-2 rounded-0"
                variant="dark">
                Restart app and go back to home page.
            </Button>
        </Fragment>
    );

    version = (
        <p>
            {`Version: ${CURRENT_VERSION_NUMBER}, Build on: ${VERSION.date}, Hash: ${HASH}, Mode: ${process.env.NODE_ENV}, App: ${SSO_APP_NAME}, Env: ${ENV_APP_NAME}`}
        </p>
    );

    render() {
        if (this.state.errorInfo) {
            if (process.env.NODE_ENV === 'production') {
                return (
                    <div className="text-center h-100 pt-5">
                        <h1>
                            <FontAwesomeIcon icon={faFaceFrown} size="2xl" />
                        </h1>
                        <h2>Something went wrong</h2>
                        <h6>
                            We are sorry that this did not worked. Simply
                            restart the app via the button below.
                            <br />
                            If this does not work, just try again in a few
                            minutes. If the error persists write us an email.
                        </h6>
                        <Table
                            striped
                            responsive
                            bordered
                            className="mt-4 w-50 mx-auto border-dark">
                            <thead>
                                <tr>
                                    <th colSpan={2}>Contact</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Service</th>
                                    <td>service@pwm.com</td>
                                </tr>
                            </tbody>
                        </Table>
                        {this.version}
                        <br />
                        {this.buttons}
                    </div>
                );
            }
            return (
                <div className="text-center">
                    <br />
                    <h2>Something went wrong.</h2>
                    <p>
                        Please take a screenshot and send it to{' '}
                        <a href="mailto:samuelb@pwm.com">samuelb@pwm.com</a>
                    </p>
                    <details
                        style={{ whiteSpace: 'pre-wrap' }}
                        open
                        className="mb-3 p-3 border">
                        <br />
                        {window.location.href}
                        <br />
                        <br />
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                    {this.version}
                    <br />
                    {this.buttons}
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
