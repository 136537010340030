import React, { Fragment, useContext, useState } from 'react';

import {
    faUserGroup,
    faFilter,
    faFolder,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';

import UserContext from '../../../context/user-context';
import ViewContext from '../../../context/view-context';
import { ApiStatusCategories } from '../../../enum/ApiStatusCategories';
import CloseButton from '../../Buttons/General/CloseButton';
import ConfirmButton from '../../Buttons/General/ConfirmButton';
import RefreshButton from '../../Buttons/General/RefreshButton';
import RemoveButton from '../../Buttons/General/RemoveButton';

const NavigationFilter = () => {
    const { t } = useTranslation('', { keyPrefix: 'navigationFilter' });
    const viewCtx = useContext(ViewContext);
    const { domain, setDomain, group, setGroup } = viewCtx;
    const [selectedDomain, setSelectedDomain] = useState(domain);
    const [selectedGroup, setSelectedGroup] = useState(group);
    const [show, setShow] = useState(false);
    const userCtx = useContext(UserContext);
    const {
        domains,
        groups,
        loadDomains,
        loadGroups,
        statusGroupsRequest,
        statusDomainsRequest,
        timestampDomains,
        timestampGroups,
    } = userCtx;

    /**
     * Öffnet oder schließt das Modal, abhängig vom vorherigen Status.
     */
    const toggleShow = () => setShow((prevState) => !prevState);

    /**
     * Setzt den Filter auf die Ausgangswerte beim Abbrechen der Filterung.
     */
    const abortHandler = () => {
        setShow(false);
        setSelectedDomain(domain);
        setSelectedGroup(group);
    };

    /**
     * Löscht den Filter und setzt die Standardwerte für die Ansicht
     */
    const deleteHandler = () => {
        setShow(false);
        setSelectedDomain(null);
        setSelectedGroup(null);
        setDomain(null);
        setGroup(null);
    };

    /**
     * Setzt den Filter auf die gewünschte Ansicht
     */
    const confirmHandler = () => {
        setShow(false);
        setDomain(selectedDomain);
        setGroup(selectedGroup);
    };

    /**
     * Aktualisiert die ausgewählte Domain
     * @param domainId
     */
    const onDomainChangeHandler = (domainId) => {
        let domain = domains.find((domain) => domain.ID_Domain === domainId);
        if (domain === undefined) {
            setSelectedDomain(null);
        } else {
            setSelectedDomain(domain);
        }
        setSelectedGroup(null);
    };

    /**
     * Aktualisiert die ausgewählte Gruppe
     * @param groupId
     */
    const onGroupChangeHandler = (groupId) => {
        let group = groups.find((group) => group.ID_Group === groupId);
        if (group === undefined) {
            setSelectedGroup(null);
        } else {
            setSelectedGroup(group);
        }
    };

    return (
        <Fragment>
            <Button name="filter" variant="outline-light" onClick={toggleShow}>
                <FontAwesomeIcon icon={faFilter} className="me-2" />
                <span className="me-2 fw-bolder">{t('filter')}:</span>
                {selectedDomain === null && selectedGroup === null && t('none')}
                {selectedDomain !== null && (
                    <span>{`${t('domain')}: ${
                        selectedDomain.DomainName === ''
                            ? selectedDomain.Descr
                            : selectedDomain.DomainName
                    }`}</span>
                )}
                {selectedGroup !== null && (
                    <span className="ms-2">{`${t('group')}: ${
                        selectedGroup.GroupName
                    }`}</span>
                )}
            </Button>
            <Modal show={show} onHide={abortHandler} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faFilter} className="me-2" />
                        {t('title')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('description')}</p>
                    <Row>
                        <Col lg={6} md={12}>
                            <Form.Group>
                                <Form.Label>
                                    <FontAwesomeIcon
                                        icon={faFolder}
                                        className="me-2"
                                    />
                                    {t('domains.label')}
                                </Form.Label>
                                <InputGroup>
                                    <Typeahead
                                        isLoading={
                                            statusDomainsRequest ===
                                            ApiStatusCategories.PENDING
                                        }
                                        clearButton={true}
                                        defaultSelected={
                                            selectedDomain
                                                ? [selectedDomain]
                                                : []
                                        }
                                        id="domains"
                                        name="domains"
                                        labelKey={(option) =>
                                            `${option?.DomainName} : ${option?.Descr}`
                                        }
                                        options={domains}
                                        disabled={selectedGroup}
                                        onChange={(e) => {
                                            onDomainChangeHandler(
                                                e[0]?.ID_Domain
                                            );
                                        }}
                                        placeholder={t(
                                            'domains.nothingSelected'
                                        )}
                                    />
                                    <RefreshButton
                                        status={statusDomainsRequest}
                                        timestamp={timestampDomains}
                                        iconOnly={true}
                                        refreshHandler={loadDomains}
                                        disabled={selectedGroup}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={12}>
                            <Form.Group>
                                <Form.Label>
                                    <FontAwesomeIcon
                                        icon={faUserGroup}
                                        className="me-2"
                                    />
                                    {t('groups.label')}
                                </Form.Label>
                                <InputGroup>
                                    <Typeahead
                                        isLoading={
                                            statusGroupsRequest ===
                                            ApiStatusCategories.PENDING
                                        }
                                        clearButton={true}
                                        defaultSelected={
                                            selectedGroup ? [selectedGroup] : []
                                        }
                                        id="groups"
                                        name="groups"
                                        labelKey={(option) =>
                                            `${option?.GroupName} : ${option?.Descr}`
                                        }
                                        options={
                                            selectedDomain
                                                ? groups.filter(
                                                      (group) =>
                                                          group.ID_Domain ===
                                                          selectedDomain.ID_Domain
                                                  )
                                                : []
                                        }
                                        disabled={!selectedDomain}
                                        onChange={(e) => {
                                            onGroupChangeHandler(
                                                e[0]?.ID_Group
                                            );
                                        }}
                                        placeholder={t(
                                            'groups.nothingSelected'
                                        )}
                                    />
                                    <RefreshButton
                                        status={statusGroupsRequest}
                                        timestamp={timestampGroups}
                                        iconOnly={true}
                                        refreshHandler={loadGroups}
                                        disabled={!selectedDomain}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row className="w-100 m-0">
                        <Col lg={3} md={12}>
                            <CloseButton
                                closeHandler={abortHandler}
                                className="w-100"
                            />
                        </Col>
                        <Col lg={3} md={12}>
                            <RemoveButton
                                removeHandler={deleteHandler}
                                className="w-100"
                            />
                        </Col>
                        <Col lg={6} md={12}>
                            <ConfirmButton
                                confirmHandler={confirmHandler}
                                className="w-100"
                            />
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default NavigationFilter;
