import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import React, { Suspense } from 'react';

import { BrowserRouter } from 'react-router-dom';

import ErrorBoundary from './components/ErrorBoundary';
import Layout from './components/Layout/Layout';
import LoadingPage from './components/UI/LoadingPage';
import { AppContextProvider } from './context/app-context';
import { AuthContextProvider } from './context/auth-context';
import 'moment-timezone';
import Pages from './Pages';
import './App.css';
import './i18n';

/**
 * Diese Komponente ist der Einstiegspunkt der Web-App.
 * @returns {JSX.Element}
 * @constructor
 */
function App() {
    return (
        <Suspense fallback={<LoadingPage />}>
            <AuthContextProvider>
                <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
                    <AppContextProvider>
                        <ErrorBoundary>
                            <Layout>
                                <Pages />
                            </Layout>
                        </ErrorBoundary>
                    </AppContextProvider>
                </BrowserRouter>
            </AuthContextProvider>
        </Suspense>
    );
}

export default App;
