import React, { useContext } from 'react';

import {
    faFacebookSquare,
    faInstagramSquare,
    faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import {
    faArrowPointer,
    faCopyright,
    faEnvelope,
    faExternalLink,
    faPhone,
    faSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment/moment';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LogoDoohit from '../../assets/logos/just-dooh-it-black.png';
import UserContext from '../../context/user-context';
import metadata from '../../metadata.json';

const SSO_APP_NAME = process.env.REACT_APP_SSO_APP_NAME;
const ENV_APP_NAME = process.env.REACT_APP_ENV_APP_NAME;

const { version: VERSION, commit: HASH } = metadata;
const CURRENT_VERSION_NUMBER = `${VERSION.major}.${VERSION.minor}.${VERSION.revision}-${VERSION.buildnumber}`;

const Footer = () => {
    const { t } = useTranslation('', { keyPrefix: 'footer' });
    const userCtx = useContext(UserContext);
    const { timezone, combinedDateTimeFormat } = userCtx;

    return (
        <footer className="px-4 mt-auto pt-3 text-muted">
            <Row className="g-5">
                <Col md={4} lg={2} className="border-end">
                    <h5 className="text-decoration-underline">
                        {t('pwm.title')}
                    </h5>
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2">
                            <Link
                                to="https://pwm.com/kontakte"
                                target="_blank"
                                rel="nofollow"
                                className="link-secondary link-underline-opacity-0">
                                <FontAwesomeIcon
                                    icon={faExternalLink}
                                    fixedWidth={true}
                                    className="me-1"
                                />
                                {t('pwm.contact-persons.global')}
                            </Link>
                        </li>
                        <li className="nav-item mb-2">
                            <Link
                                to="https://usa.pwm.com/contact"
                                target="_blank"
                                rel="nofollow"
                                className="link-secondary link-underline-opacity-0">
                                <FontAwesomeIcon
                                    icon={faExternalLink}
                                    fixedWidth={true}
                                    className="me-1"
                                />
                                {t('pwm.contact-persons.usa')}
                            </Link>
                        </li>
                        <li className="nav-item mb-2">
                            <Link
                                to="https://www.pwm.com"
                                target="_blank"
                                rel="nofollow"
                                className="link-secondary link-underline-opacity-0">
                                <FontAwesomeIcon
                                    icon={faExternalLink}
                                    fixedWidth={true}
                                    className="me-1"
                                />
                                www.pwm.com
                            </Link>
                        </li>
                        <li className="nav-item mb-2">
                            <Link
                                to="https://pwm.com/datenschutz"
                                target="_blank"
                                rel="nofollow"
                                className="link-secondary link-underline-opacity-0">
                                <FontAwesomeIcon
                                    icon={faExternalLink}
                                    fixedWidth={true}
                                    className="me-1"
                                />
                                {t('pwm.privacy')}
                            </Link>
                        </li>
                        <li className="nav-item mb-2">
                            <Link
                                to="https://pwm.com/impressum"
                                target="_blank"
                                rel="nofollow"
                                className="link-secondary link-underline-opacity-0">
                                <FontAwesomeIcon
                                    icon={faExternalLink}
                                    fixedWidth={true}
                                    className="me-1"
                                />
                                {t('pwm.imprint')}
                            </Link>
                        </li>
                    </ul>
                </Col>
                <Col md={8} lg={4} className="border-end">
                    <Row>
                        <Col sm={12}>
                            <h5 className="text-decoration-underline">
                                {t('dooh-it.title')}
                            </h5>
                        </Col>
                        <Col sm={12}>
                            <p>{t('dooh-it.description')}</p>
                            <hr />
                        </Col>
                        <Col sm={4} className="my-auto">
                            <Image src={LogoDoohit} fluid />
                        </Col>
                        <Col sm={8}>
                            <h6>{t('dooh-it.more')}</h6>
                            <Link
                                to="https://doohit.de/"
                                target="_blank"
                                className="link-secondary link-underline-opacity-0">
                                <FontAwesomeIcon
                                    icon={faExternalLink}
                                    fixedWidth={true}
                                    className="me-1"
                                />
                                {t('dooh-it.click')}
                                <FontAwesomeIcon
                                    icon={faArrowPointer}
                                    className="ms-2"
                                />
                            </Link>
                        </Col>
                    </Row>
                </Col>
                <Col md={8} lg={4} className="border-end">
                    <Row>
                        <Col sm={12}>
                            <h5 className="text-decoration-underline">
                                {t('service.title')}
                            </h5>
                            <p>{t('service.description')}</p>
                            <hr />
                        </Col>
                        <Col sm={6}>
                            <h6 className="text-decoration-underline">
                                {t('service.usa.title')}
                            </h6>
                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <th>
                                            <FontAwesomeIcon
                                                icon={faEnvelope}
                                                fixedWidth={true}
                                            />
                                        </th>
                                        <th>{t('service.mail')}</th>
                                        <td>
                                            <a
                                                className="link-secondary link-underline-opacity-0"
                                                href={`mailto:${t(
                                                    'service.usa.mail'
                                                )}`}>
                                                {t('service.usa.mail')}
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <FontAwesomeIcon
                                                icon={faPhone}
                                                fixedWidth={true}
                                            />
                                        </th>
                                        <th>{t('service.number')}</th>
                                        <td>
                                            <a
                                                className="link-secondary link-underline-opacity-0"
                                                href={`tel:${t(
                                                    'service.usa.number-src'
                                                )}`}>
                                                {t('service.usa.number')}
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col sm={6}>
                            <h6 className="text-decoration-underline">
                                {t('service.global.title')}
                            </h6>
                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <th>
                                            <FontAwesomeIcon
                                                icon={faEnvelope}
                                                fixedWidth={true}
                                            />
                                        </th>
                                        <th>{t('service.mail')}</th>
                                        <td>
                                            <a
                                                className="link-secondary link-underline-opacity-0"
                                                href={`mailto:${t(
                                                    'service.global.mail'
                                                )}`}>
                                                {t('service.global.mail')}
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <FontAwesomeIcon
                                                icon={faPhone}
                                                fixedWidth={true}
                                            />
                                        </th>
                                        <th>{t('service.number')}</th>
                                        <td>
                                            <a
                                                className="link-secondary link-underline-opacity-0"
                                                href={`tel:${t(
                                                    'service.global.number-src'
                                                )}`}>
                                                {t('service.global.number')}
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Col>
                <Col md={4} lg={2}>
                    <h5 className="text-decoration-underline">
                        {t('follow-us.title')}
                    </h5>
                    <p>{t('follow-us.description')}</p>
                    <Row className="g-4">
                        <Col sm="4" className="text-center">
                            <Link
                                to="https://www.instagram.com/pwm_germany/"
                                rel="nofollow"
                                target="_blank"
                                className="link-secondary link-underline-opacity-0">
                                <FontAwesomeIcon
                                    size="3x"
                                    icon={faInstagramSquare}
                                    fixedWidth
                                />
                            </Link>
                        </Col>
                        <Col sm="4" className="text-center">
                            <Link
                                to="https://www.facebook.com/pwm.preisanzeigen/"
                                rel="nofollow"
                                target="_blank"
                                className="link-secondary link-underline-opacity-0">
                                <FontAwesomeIcon
                                    size="3x"
                                    icon={faFacebookSquare}
                                    fixedWidth
                                />
                            </Link>
                        </Col>
                        <Col sm="4" className="text-center">
                            <Link
                                to="https://www.linkedin.com/company/pwm-germany"
                                rel="nofollow"
                                target="_blank"
                                className="link-secondary link-underline-opacity-0">
                                <FontAwesomeIcon
                                    size="3x"
                                    icon={faLinkedin}
                                    fixedWidth
                                />
                            </Link>
                        </Col>
                        <Col sm="4" className="text-center">
                            <Link
                                to="#"
                                className="link-secondary link-underline-opacity-0">
                                <FontAwesomeIcon
                                    opacity="5%"
                                    size="3x"
                                    icon={faSquare}
                                    fixedWidth
                                />
                            </Link>
                        </Col>
                        <Col sm="4" className="text-center">
                            <Link
                                to="#"
                                className="link-secondary link-underline-opacity-0">
                                <FontAwesomeIcon
                                    opacity="5%"
                                    size="3x"
                                    icon={faSquare}
                                    fixedWidth
                                />
                            </Link>
                        </Col>
                        <Col sm="4" className="text-center">
                            <Link
                                to="#"
                                className="link-secondary link-underline-opacity-0">
                                <FontAwesomeIcon
                                    opacity="5%"
                                    size="3x"
                                    icon={faSquare}
                                    fixedWidth
                                />
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="d-flex justify-content-between pt-3 mb-2 mt-3 border-top">
                <p>
                    <FontAwesomeIcon icon={faCopyright} className="me-2" />
                    <span>{new Date().getFullYear()}, </span>
                    {t('copyright')}
                </p>
                <p className="small">
                    {`Version: ${CURRENT_VERSION_NUMBER}, Build on: ${moment(VERSION.date).tz(timezone).format(combinedDateTimeFormat)}, Hash: ${HASH}, Mode: ${process.env.NODE_ENV}, App: ${SSO_APP_NAME}, Env: ${ENV_APP_NAME}`}
                </p>
            </div>
        </footer>
    );
};

export default Footer;
