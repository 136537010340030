import React from 'react';

import { Navigate } from 'react-router-dom';

import ExtendedRoutes from '../../../components/ExtendedRoutes/ExtendedRoutes';
import { PrivilegesCategories } from '../../../enum/PrivilegesCategories';

const CreateDashboardItem = React.lazy(() => import('./CreateDashboardItem'));
const EditLayoutDashboard = React.lazy(() => import('./EditDashboardLayout'));
const EditDashboardItem = React.lazy(() => import('./EditDashboardItem'));
const CreateDashboard = React.lazy(() => import('./CreateDashboard'));
const EditDashboard = React.lazy(() => import('./EditDashboard'));
const Dashboards = React.lazy(() => import('./Dashboards'));
const Dashboard = React.lazy(() => import('./Dashboard'));

/**
 * In diesem Array werden alle Dashboardseiten mit dem Pfad, der benötigten Berechtigung und ob diese im Slave aufgerufen werden können, definiert.
 */
const routes = [
    {
        path: '/',
        Component: <Dashboards />,
        needsGroupPrivilege: [
            PrivilegesCategories.Group_Dashboard,
            PrivilegesCategories.Domain_Dashboard,
        ],
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/create',
        Component: <CreateDashboard />,
        needsGroupPrivilege: [
            PrivilegesCategories.Group_Dashboard_Create,
            PrivilegesCategories.Domain_Dashboard_Create,
        ],
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/:dashboardId/edit',
        Component: <EditDashboard />,
        needsGroupPrivilege: [
            PrivilegesCategories.Group_Dashboard_Edit,
            PrivilegesCategories.Domain_Dashboard_Edit,
        ],
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/:dashboardId',
        Component: <Dashboard />,
        needsGroupPrivilege: [
            PrivilegesCategories.Group_Dashboard,
            PrivilegesCategories.Domain_Dashboard,
        ],
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: true,
    },
    {
        path: '/:dashboardId/items/edit',
        Component: <EditLayoutDashboard />,
        needsGroupPrivilege: [
            PrivilegesCategories.Group_Dashboard_Edit,
            PrivilegesCategories.Domain_Dashboard_Edit,
        ],
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/:dashboardId/items/create',
        Component: <CreateDashboardItem />,
        needsGroupPrivilege: [
            PrivilegesCategories.Group_Dashboard_Edit,
            PrivilegesCategories.Domain_Dashboard_Edit,
        ],
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/:dashboardId/items/:itemId/edit',
        Component: <EditDashboardItem />,
        needsGroupPrivilege: [
            PrivilegesCategories.Group_Dashboard_Edit,
            PrivilegesCategories.Domain_Dashboard_Edit,
        ],
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/*',
        Component: <Navigate replace to="/home" />,
        needsGroupPrivilege: [
            PrivilegesCategories.Group_Dashboard,
            PrivilegesCategories.Domain_Dashboard,
        ],
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
];

/**
 * Diese Komponente definiert alle Seiten und überprüft ob der Benutzer
 * ausreichend Rechte besitzt, um diese Seite aufzurufen.
 * @returns {JSX.Element}
 * @constructor
 */
export const DashboardsRoutes = () => {
    return <ExtendedRoutes routes={routes} />;
};

export default DashboardsRoutes;
