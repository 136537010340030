import React, { Fragment, useContext, useRef, useState } from 'react';

import {
    faExclamationTriangle,
    faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Overlay } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';

import AppContext from '../../../context/app-context';
import userContext from '../../../context/user-context';
import { ApiStatusCategories } from '../../../enum/ApiStatusCategories';

import 'moment-timezone';

const RefreshButton = (props) => {
    const { t } = useTranslation('', { keyPrefix: 'buttons' });
    const {
        refreshHandler,
        className,
        hidden,
        disabled,
        size,
        iconOnly,
        timestamp,
        status,
    } = props;
    const appCtx = useContext(AppContext);
    const { isDarkMode } = appCtx;
    const userCtx = useContext(userContext);
    const { timezone, combinedDateTimeFormat } = userCtx;
    const [show, setShow] = useState(false);
    const target = useRef(null);

    return (
        <Fragment>
            <Button
                ref={target}
                name="refresh"
                size={size}
                className={className}
                hidden={hidden}
                disabled={disabled || status === ApiStatusCategories.PENDING}
                variant={isDarkMode ? 'outline-light' : 'dark'}
                onClick={refreshHandler}
                onFocus={() => setShow(true)}
                onBlur={() => setShow(false)}
                onPointerOver={() => setShow(true)}
                onPointerLeave={() => setShow(false)}
                onMouseOver={() => setShow(true)}
                onMouseLeave={() => setShow(false)}>
                <FontAwesomeIcon
                    icon={faRefresh}
                    spin={status === ApiStatusCategories.PENDING}
                    className={!iconOnly ? 'me-2' : ''}
                />
                {!iconOnly ? t('refresh.title') : ''}
                {status === ApiStatusCategories.FAILED && (
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="text-danger ms-2"
                    />
                )}
            </Button>
            {(timestamp || status === ApiStatusCategories.FAILED) && (
                <Overlay target={target.current} show={show} placement="bottom">
                    {status === ApiStatusCategories.FAILED ? (
                        <Tooltip>{t('refresh.failed')}</Tooltip>
                    ) : (
                        <Tooltip>
                            <span className="me-1">{t('refresh.hint')}</span>
                            {moment
                                .unix(timestamp)
                                .tz(timezone)
                                .format(combinedDateTimeFormat)}
                        </Tooltip>
                    )}
                </Overlay>
            )}
        </Fragment>
    );
};

RefreshButton.propTypes = {
    refreshHandler: PropTypes.func.isRequired,
    timestamp: PropTypes.number,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    iconOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    status: PropTypes.oneOf(Object.values(ApiStatusCategories)),
    size: PropTypes.oneOf(['sm', 'lg', null]),
};

RefreshButton.defaultProps = {
    timestamp: null,
    className: '',
    disabled: false,
    iconOnly: false,
    hidden: false,
    status: ApiStatusCategories.NULL,
    size: null,
};

export default RefreshButton;
