const API_STATION_URL = process.env.REACT_APP_API_STATION_URL;
const API_STATION_HISTORY_URL = process.env.REACT_APP_API_STATION_HISTORY_URL;
const API_STATION_DATA_URL = process.env.REACT_APP_API_STATION_DATA_URL;
const API_STATION_CONNECTDEVICE_URL =
    process.env.REACT_APP_API_STATION_CONNECTDEVICE_URL;
const API_STATION_COMMENT_URL = process.env.REACT_APP_API_STATION_COMMENT_URL;
const API_URL_V2 = process.env.REACT_APP_API_V2_URL;
const API_STATION_GRADES_URL = process.env.REACT_APP_API_STATION_GRADES_URL;
const API_STATION_GRADES_ENABLED_URL =
    process.env.REACT_APP_API_STATION_GRADES_ENABLED_URL;
const API_STATION_GRADES_SOURCE_URL =
    process.env.REACT_APP_API_STATION_GRADES_SOURCE_URL;
const API_STATION_WEB_REPORTING_URL =
    process.env.REACT_APP_API_STATION_WEB_REPORTING_URL;

export async function getStations() {
    const response = await fetch(`${API_STATION_URL}`, {
        credentials: 'include',
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch stations.');
    }

    return data;
}

export async function getGroupStations(payload) {
    const response = await fetch(
        `${API_URL_V2}/stationsofgroups/${payload.ID_Group}`,
        {
            credentials: 'include',
            method: 'GET',
        }
    );
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.ERROR || 'Could not fetch group stations data.');
    }

    return data;
}

export async function getDomainStations(payload) {
    const response = await fetch(
        `${API_URL_V2}/stationsofdomain/${payload.ID_Domain}`,
        {
            credentials: 'include',
            method: 'GET',
        }
    );
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.ERROR || 'Could not fetch group stations data.');
    }

    return data;
}

export async function getStation(stationId) {
    const response = await fetch(`${API_STATION_URL}/${stationId}`, {
        credentials: 'include',
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch station.');
    }

    return data;
}

export async function addStation(payload) {
    const response = await fetch(`${API_STATION_URL}/`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(payload.station),
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create station.');
    }

    return data;
}

export async function updateStation(payload) {
    const response = await fetch(`${API_STATION_URL}/${payload.id}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'PATCH',
        body: JSON.stringify(payload.station),
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update station.');
    }

    return data;
}

export async function deleteStation(payload) {
    const response = await fetch(`${API_STATION_URL}/${payload.id}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'DELETE',
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete station.');
    }

    return null;
}

export async function getStationGrades(stationId) {
    const response = await fetch(`${API_STATION_GRADES_URL}/${stationId}`, {
        credentials: 'include',
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch station grades.');
    }

    return data;
}

export async function updateStationGrades(payload) {
    const response = await fetch(`${API_STATION_GRADES_URL}/${payload.id}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(payload.data),
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update station grades.');
    }

    return data;
}

export async function getStationGradesEnabled(stationId) {
    const response = await fetch(
        `${API_STATION_GRADES_ENABLED_URL}/${stationId}`,
        {
            credentials: 'include',
        }
    );
    const data = await response.json();

    if (!response.ok) {
        throw new Error(
            data.message || 'Could not fetch station grades enabled.'
        );
    }

    return data;
}

export async function updateStationGradesEnabled(payload) {
    const response = await fetch(
        `${API_STATION_GRADES_ENABLED_URL}/${payload.id}`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(payload.data),
        }
    );
    const data = await response.json();

    if (!response.ok) {
        throw new Error(
            data.message || 'Could not update station grades enabled.'
        );
    }

    return data;
}

export async function getStationGradesSource(stationId) {
    const response = await fetch(
        `${API_STATION_GRADES_SOURCE_URL}/${stationId}`,
        {
            credentials: 'include',
        }
    );
    const data = await response.json();

    if (!response.ok) {
        throw new Error(
            data.message || 'Could not fetch station grades source.'
        );
    }

    return data;
}

export async function updateStationGradesSource(payload) {
    const response = await fetch(
        `${API_STATION_GRADES_SOURCE_URL}/${payload.id}`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(payload.data),
        }
    );
    const data = await response.json();

    if (!response.ok) {
        throw new Error(
            data.message || 'Could not update station grades enabled.'
        );
    }

    return data;
}

export async function connectStation(payload) {
    const response = await fetch(
        `${API_STATION_CONNECTDEVICE_URL}/${payload.id}`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ Serial: payload.serial }),
        }
    );
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not connect station.');
    }

    return data;
}

export async function disconnectStation(payload) {
    const response = await fetch(
        `${API_STATION_CONNECTDEVICE_URL}/${payload.id}`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            method: 'DELETE',
        }
    );
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not disconnect station.');
    }

    return data;
}

export async function getStationHistory(payload) {
    const response = await fetch(`${API_STATION_HISTORY_URL}/${payload.id}`, {
        credentials: 'include',
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch station history.');
    }

    return data;
}

export async function addStationData(payload) {
    const response = await fetch(
        `${API_STATION_DATA_URL}/${payload.stationId}/${payload.name}`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ Value: payload.values }),
        }
    );
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create station data.');
    }

    return data;
}

export async function getStationData(payload) {
    const response = await fetch(
        `${API_STATION_DATA_URL}/${payload.stationId}/${payload.name ?? ''}`,
        {
            credentials: 'include',
            method: 'GET',
        }
    );
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch station data.');
    }

    return data;
}

export async function getStationComments(payload) {
    const response = await fetch(`${API_STATION_COMMENT_URL}/${payload.id}`, {
        credentials: 'include',
        method: 'GET',
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch station comments.');
    }

    return data;
}

export async function addStationComment(payload) {
    const response = await fetch(`${API_STATION_COMMENT_URL}/${payload.id}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
            Comment: payload.comment,
            Confidential: payload.confidential,
        }),
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not add station comment.');
    }

    return data;
}

export async function deleteStationComment(payload) {
    const response = await fetch(`${API_STATION_COMMENT_URL}/${payload.id}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'DELETE',
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete station comment.');
    }

    return null;
}

export async function updateStationComment(payload) {
    const response = await fetch(`${API_STATION_COMMENT_URL}/${payload.id}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'PATCH',
        body: JSON.stringify({
            Comment: payload.comment,
            Confidential: payload.confidential,
        }),
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update station comment.');
    }

    return data;
}

export async function updateStationWebReporting(payload) {
    const response = await fetch(
        `${API_STATION_WEB_REPORTING_URL}/${payload.id}`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(payload.data),
        }
    );

    if (!response.ok) {
        throw new Error('Could not update station web reporting.');
    }
}
