import React, { useContext } from 'react';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppContext from '../../context/app-context';

const MetadataMessage = () => {
    const appCtx = useContext(AppContext);
    const { metadata } = appCtx;

    return (
        metadata?.message?.show && (
            <div
                className={`text-bg-${
                    metadata?.message?.variant ?? 'primary'
                } text-white text-center m-0 p-1`}>
                <h5 className="m-1">
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        fixedWidth
                        className="me-2"
                    />
                    <span className="fw-bolder me-2">
                        {metadata?.message?.title ?? ''}:
                    </span>
                    {metadata?.message?.description ?? ''}
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        fixedWidth
                        className="ms-2"
                    />
                </h5>
            </div>
        )
    );
};

export default MetadataMessage;
