import { useReducer, useCallback, useContext } from 'react';

import moment from 'moment';

import AppContext from '../context/app-context';
import AuthContext from '../context/auth-context';

function httpReducer(state, action) {
    if (action.type === 'SEND') {
        return {
            data: null,
            error: null,
            status: 'pending',
            timestamp: null,
        };
    }

    if (action.type === 'SUCCESS') {
        return {
            data: action.responseData,
            error: null,
            status: 'completed',
            timestamp: moment().unix(),
        };
    }

    if (action.type === 'ERROR') {
        return {
            data: null,
            error: action.errorMessage,
            status: 'failed',
            timestamp: null,
        };
    }

    return state;
}

function useHttp(requestFunction, startWithPending = false) {
    const authCtx = useContext(AuthContext);
    const { checkLogIn } = authCtx;
    const appCtx = useContext(AppContext);
    const { registerRequest, unregisterRequest } = appCtx;
    const [httpState, dispatch] = useReducer(httpReducer, {
        status: startWithPending ? 'pending' : null,
        data: null,
        error: null,
        timestamp: null,
    });

    const sendRequest = useCallback(
        function (requestData) {
            (async () => {
                registerRequest();
                dispatch({ type: 'SEND' });
                try {
                    const responseData = await requestFunction(requestData);
                    unregisterRequest();
                    dispatch({ type: 'SUCCESS', responseData });
                } catch (error) {
                    unregisterRequest();
                    checkLogIn();
                    dispatch({
                        type: 'ERROR',
                        errorMessage: error.message || 'Something went wrong!',
                    });
                }
            })();
        },
        [registerRequest, unregisterRequest, checkLogIn, requestFunction]
    );

    return {
        sendRequest,
        ...httpState,
    };
}

export default useHttp;
