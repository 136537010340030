import React from 'react';

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const LoadingComponentFailed = () => {
    const { t } = useTranslation('', { keyPrefix: 'loadingFailed' });

    return (
        <Row>
            <Col sm={12} className="text-center text-danger">
                <h1>
                    <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="me-2"
                    />
                    {t('title')}
                    <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="ms-2"
                    />
                </h1>
                <h6>{t('description')}</h6>
            </Col>
        </Row>
    );
};

export default LoadingComponentFailed;
