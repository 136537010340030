import React from 'react';

import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ConfirmButton = (props) => {
    const { t } = useTranslation('', { keyPrefix: 'buttons' });
    const { confirmHandler, className, hidden, disabled, size } = props;

    return (
        <Button
            name="confirm"
            size={size}
            className={className}
            hidden={hidden}
            disabled={disabled}
            variant="success"
            onClick={confirmHandler}>
            <FontAwesomeIcon icon={faCheckSquare} className="me-2" />
            {t('confirm')}
        </Button>
    );
};

ConfirmButton.propTypes = {
    confirmHandler: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'lg', null]),
};

ConfirmButton.defaultProps = {
    className: '',
    disabled: false,
    hidden: false,
    size: null,
};

export default ConfirmButton;
