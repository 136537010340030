import React from 'react';

import { faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const RemoveButton = (props) => {
    const { t } = useTranslation('', { keyPrefix: 'buttons' });
    const {
        removeHandler,
        className,
        hidden,
        disabled,
        children,
        size,
        alias,
        iconOnly,
    } = props;
    return (
        <Button
            name={alias ? 'remove-' + alias : 'remove'}
            size={size}
            className={className}
            hidden={hidden}
            disabled={disabled}
            variant="danger"
            onClick={removeHandler}>
            <FontAwesomeIcon
                icon={faMinusSquare}
                className={!iconOnly ? 'me-2' : ''}
            />
            {!iconOnly ? (children ? children : t('remove')) : ''}
        </Button>
    );
};

RemoveButton.propTypes = {
    removeHandler: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    iconOnly: PropTypes.bool,
    children: PropTypes.node,
    hidden: PropTypes.bool,
    alias: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'lg', null]),
};

RemoveButton.defaultProps = {
    className: '',
    disabled: false,
    iconOnly: false,
    children: null,
    hidden: false,
    alias: null,
    size: null,
};

export default RemoveButton;
