import React from 'react';

import { Navigate } from 'react-router-dom';

import ExtendedRoutes from '../../../../../components/ExtendedRoutes/ExtendedRoutes';
import { PrivilegesCategories } from '../../../../../enum/PrivilegesCategories';

const Projects = React.lazy(() => import('./Projects'));
const Devices = React.lazy(() => import('./Devices'));

/**
 * In diesem Array werden alle Seiten mit dem Pfad, der benötigten Berechtigung und ob diese im Slave aufgerufen werden können, definiert.
 */
const routes = [
    {
        path: '/projects',
        Component: <Projects />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Service,
            PrivilegesCategories.PWM_Development,
            PrivilegesCategories.PWM_ReadOnly,
            PrivilegesCategories.PWM_Admin,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/projects/:projectId/devices',
        Component: <Devices />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Service,
            PrivilegesCategories.PWM_Development,
            PrivilegesCategories.PWM_ReadOnly,
            PrivilegesCategories.PWM_Admin,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/*',
        Component: <Navigate replace to="/projects" />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Service,
            PrivilegesCategories.PWM_Development,
            PrivilegesCategories.PWM_ReadOnly,
            PrivilegesCategories.PWM_Admin,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
];

/**
 * Diese Komponente definiert alle Seiten und überprüft ob der Benutzer
 * ausreichend Rechte besitzt, um diese Seite aufzurufen.
 * @returns {JSX.Element}
 * @constructor
 */
export const DisruptiveTechnologiesRoutes = () => {
    return <ExtendedRoutes routes={routes} />;
};

export default DisruptiveTechnologiesRoutes;
