import React, { useCallback, useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';

import UserContext from './user-context';
import { getRights } from '../api/api-rights';
import LoadingComponent from '../components/UI/LoadingComponent';
import LoadingComponentFailed from '../components/UI/LoadingComponentFailed';
import { ApiStatusCategories } from '../enum/ApiStatusCategories';
import useHttp from '../hooks/useHttp';

const ObjectContext = React.createContext({
    hasObjectPrivilege: () => null,
    reloadObjectContext: () => null,
    objectId: '',
});

export const ObjectContextProvider = (props) => {
    const {
        children,
        objectId: propObjectId,
        showLoadingComponent,
        requiredRights,
    } = props;
    const params = useParams();
    const { objectId: paramObjectId } = params;
    const objectId = propObjectId ?? paramObjectId;
    const userCtx = useContext(UserContext);
    const { userId, isAdmin } = userCtx;
    const navigate = useNavigate();
    const {
        sendRequest: sendObjectPrivilegesRequest,
        status: statusObjectPrivilegesRequest,
        data: objectPrivilegesData,
    } = useHttp(getRights, true);

    const hasObjectPrivilege = useCallback(
        (categories) => {
            if (Array.isArray(categories)) {
                for (let category of categories) {
                    if (
                        isAdmin ||
                        (objectPrivilegesData['Rights'][category] ?? false)
                    ) {
                        return true;
                    }
                }
            } else {
                return (
                    isAdmin ||
                    (objectPrivilegesData['Rights'][categories] ?? false)
                );
            }
            return false;
        },
        [objectPrivilegesData, isAdmin]
    );

    const reloadObjectContext = useCallback(() => {
        if (objectId !== undefined) {
            sendObjectPrivilegesRequest({
                params: {
                    ID_Target: objectId,
                    ID_Member: userId,
                },
            });
        }
    }, [objectId, userId, sendObjectPrivilegesRequest]);

    useEffect(() => {
        reloadObjectContext();
    }, [reloadObjectContext]);

    useEffect(() => {
        if (
            statusObjectPrivilegesRequest === ApiStatusCategories.COMPLETED &&
            requiredRights != null
        ) {
            if (!hasObjectPrivilege(requiredRights)) {
                navigate('/');
            }
        }
    }, [
        statusObjectPrivilegesRequest,
        hasObjectPrivilege,
        requiredRights,
        navigate,
    ]);

    return (
        <ObjectContext.Provider
            value={{
                hasObjectPrivilege: hasObjectPrivilege,
                reloadObjectContext: reloadObjectContext,
                objectId: objectId,
            }}>
            {statusObjectPrivilegesRequest === ApiStatusCategories.COMPLETED &&
                children}
            {statusObjectPrivilegesRequest === ApiStatusCategories.PENDING &&
                showLoadingComponent && <LoadingComponent />}
            {statusObjectPrivilegesRequest === ApiStatusCategories.FAILED && (
                <LoadingComponentFailed />
            )}
        </ObjectContext.Provider>
    );
};

ObjectContext.propTypes = {
    children: PropTypes.any.isRequired,
    objectId: PropTypes.string,
    showLoadingComponent: PropTypes.bool,
    requiredRights: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

ObjectContext.defaultProps = {
    objectId: null,
    showLoadingComponent: true,
    requiredRights: null,
};

export default ObjectContext;
