import React from 'react';

import { Navigate } from 'react-router-dom';

import ExtendedRoutes from '../../../components/ExtendedRoutes/ExtendedRoutes';
import { PrivilegesCategories } from '../../../enum/PrivilegesCategories';
import { RightsRoutes } from '../../rights';

const CreateDomain = React.lazy(() => import('./CreateDomain'));
const EditDomain = React.lazy(() => import('./EditDomain'));
const Domain = React.lazy(() => import('./Domain'));
const Domains = React.lazy(() => import('./Domains'));

/**
 * In diesem Array werden alle Seiten mit dem Pfad, der benötigten Berechtigung und ob diese im Slave aufgerufen werden können, definiert.
 */
const routes = [
    {
        path: '/',
        Component: <Domains />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/create',
        Component: <CreateDomain />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: true,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/:objectId',
        Component: <Domain />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: PrivilegesCategories.Domain,
        initialObjectContext: true,
        slaveAccessible: false,
    },
    {
        path: '/:objectId/edit',
        Component: <EditDomain />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: PrivilegesCategories.Domain_Edit,
        initialObjectContext: true,
        slaveAccessible: false,
    },
    {
        path: '/:objectId/rights/*',
        Component: <RightsRoutes />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: PrivilegesCategories.RightsView_User,
        initialObjectContext: true,
        slaveAccessible: false,
    },
    {
        path: '/*',
        Component: <Navigate replace to="/" />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
];

/**
 * Diese Komponente definiert alle Seiten und überprüft ob der Benutzer
 * ausreichend Rechte besitzt, um diese Seite aufzurufen.
 * @returns {JSX.Element}
 * @constructor
 */
export const DomainsRoutes = () => {
    return <ExtendedRoutes routes={routes} />;
};

export default DomainsRoutes;
