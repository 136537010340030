const API_RIGHT_URL = process.env.REACT_APP_API_RIGHT_URL;

export async function getRights(payload) {
    const response = await fetch(
        `${API_RIGHT_URL}?` +
            new URLSearchParams({
                ...payload.params,
            }),
        {
            credentials: 'include',
            method: 'GET',
        }
    );
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.ERROR || 'Could not fetch rights data.');
    }

    return data;
}

export async function checkRights() {
    const response = await fetch(`${API_RIGHT_URL}/check`, {
        credentials: 'include',
        method: 'GET',
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.ERROR || 'Could not fetch check rights data.');
    }

    return data;
}
