import React from 'react';

import { Navigate } from 'react-router-dom';

import ExtendedRoutes from '../../../components/ExtendedRoutes/ExtendedRoutes';
import { PrivilegesCategories } from '../../../enum/PrivilegesCategories';

const Templates = React.lazy(() => import('./Templates'));
const Template = React.lazy(() => import('./Template'));

/**
 * In diesem Array werden alle Seiten mit dem Pfad, der benötigten Berechtigung und ob diese im Slave aufgerufen werden können, definiert.
 */
const routes = [
    {
        path: '/',
        Component: <Templates />,
        needsGroupPrivilege: [
            PrivilegesCategories.Group_Dashboard_Template,
            PrivilegesCategories.Domain_Dashboard_Template,
        ],
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/:templateId',
        Component: <Template />,
        needsGroupPrivilege: [
            PrivilegesCategories.Group_Dashboard_Template,
            PrivilegesCategories.Domain_Dashboard_Template,
        ],
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/*',
        Component: <Navigate replace to="/home" />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
];

/**
 * Diese Komponente definiert alle Seiten und überprüft ob der Benutzer
 * ausreichend Rechte besitzt, um diese Seite aufzurufen.
 * @returns {JSX.Element}
 * @constructor
 */
export const TemplatesRoutes = () => {
    return <ExtendedRoutes routes={routes} />;
};

export default TemplatesRoutes;
