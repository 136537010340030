import React from 'react';

import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row } from 'react-bootstrap';

const TechnicalIssues = () => {
    return (
        <Container className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
            <Row className="m-auto g-5">
                <Col sm={12}>
                    <hr />
                </Col>
                <Col lg={2} className="text-center">
                    <FontAwesomeIcon
                        icon={faScrewdriverWrench}
                        size="8x"
                        className="mb-2 text-muted opacity-50"
                    />
                </Col>
                <Col lg={8} className="text-center">
                    <h1>Technical issues</h1>
                    <p className="fs-5 text-muted">
                        We are sorry that you cannot access this page at the
                        moment. We are currently experiencing some{' '}
                        <code>technical issues</code> which we are
                        investigating. Please try again at a later time.
                    </p>
                </Col>
                <Col lg={2} className="text-center">
                    <FontAwesomeIcon
                        icon={faScrewdriverWrench}
                        size="8x"
                        className="mb-2 text-muted opacity-50"
                    />
                </Col>
                <Col sm={12}>
                    <hr />
                </Col>
            </Row>
        </Container>
    );
};

export default TechnicalIssues;
