import React from 'react';

import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';

/**
 * Diese Komponente ist der Einstiegspunkt der Webanwendung.
 * @type {Root}
 */
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
