import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import interfaceLanguage from './data/interfaceLanguage';

const I18N_DEBUG = process.env.REACT_APP_I18N_DEBUG === 'true';
const REACT_APP_BASENAME = process.env.REACT_APP_BASENAME;

i18n
    // wire up the backend as a plugin.
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        supportedLngs: interfaceLanguage,
        debug: I18N_DEBUG,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            allowMultiLoading: false,
            loadPath: REACT_APP_BASENAME + 'locales/{{lng}}//translation.json',
            requestOptions: { cache: 'no-store' },
        },
    });

export default i18n;
