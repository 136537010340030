const API_DOMAIN_URL = process.env.REACT_APP_API_DOMAIN_URL;

export async function getDomains() {
    const response = await fetch(`${API_DOMAIN_URL}`, {
        credentials: 'include',
        method: 'GET',
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.ERROR || 'Could not fetch domains data.');
    }

    return data;
}

export async function getDomain(payload) {
    const response = await fetch(`${API_DOMAIN_URL}/${payload.objectId}`, {
        credentials: 'include',
        method: 'GET',
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.ERROR || 'Could not fetch domain data.');
    }

    return data;
}

export async function updateDomainTemplate(payload) {
    const response = await fetch(
        `${API_DOMAIN_URL}/${payload.objectId}/${payload.templateName}`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            method: 'POST',
            body: payload.templateRights,
        }
    );

    if (!response.ok) {
        throw new Error('Could not update domain template rights.');
    }
}
