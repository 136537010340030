import React, { useContext } from 'react';

import { Container, ToastContainer } from 'react-bootstrap';

import Footer from './Footer';
import Navigation from './Navigation/Navigation';
import AppContext from '../../context/app-context';
import { UserContextProvider } from '../../context/user-context';
import { GroupContextProvider } from '../../context/view-context';
import ConnectionWarning from '../ConnectionWarning/ConnectionWarning';
import MetadataMessage from '../UI/MetadataMessage';
import ToastElement from '../UI/ToastElement';

const Layout = (props) => {
    const appCtx = useContext(AppContext);
    const { alerts } = appCtx;

    return (
        <UserContextProvider>
            <GroupContextProvider>
                <ConnectionWarning />
                <MetadataMessage />
                <Navigation />
                <main className="flex-shrink-0">
                    <Container
                        className="pt-3 pb-4 px-3 border-bottom shadow-sm-bottom"
                        fluid>
                        {props.children}
                    </Container>
                </main>
                <ToastContainer className="position-fixed bottom-0 end-0 p-3">
                    {alerts &&
                        alerts.map((alert) => {
                            return (
                                <ToastElement
                                    key={alert.id}
                                    variant={alert.variant}
                                    title={alert.title}
                                    description={alert.description}
                                    to={alert?.to}
                                />
                            );
                        })}
                </ToastContainer>
                <Footer />
            </GroupContextProvider>
        </UserContextProvider>
    );
};

export default Layout;
