import React, { useContext } from 'react';

import { faSlash, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import AppContext from '../../context/app-context';

const ConnectionWarning = () => {
    const appCtx = useContext(AppContext);
    const { t } = useTranslation('', { keyPrefix: 'connection-warning' });
    const { isOnline } = appCtx;

    return !isOnline ? (
        <div className="bg-danger text-white text-center fw-bolder m-0 p-1">
            <p className="m-0">
                <span className="fa-layers fa-fw me-2">
                    <FontAwesomeIcon icon={faWifi} fixedWidth />
                    <FontAwesomeIcon icon={faSlash} fixedWidth />
                </span>
                {t('offline')}
            </p>
        </div>
    ) : null;
};

export default ConnectionWarning;
