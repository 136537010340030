import React from 'react';

import { Navigate } from 'react-router-dom';

import ExtendedRoutes from '../../../components/ExtendedRoutes/ExtendedRoutes';
import { PrivilegesCategories } from '../../../enum/PrivilegesCategories';
import { RightsRoutes } from '../../rights';

const CreateToken = React.lazy(() => import('./CreateToken'));
const Tokens = React.lazy(() => import('./Tokens'));
const Token = React.lazy(() => import('./Token'));

/**
 * In diesem Array werden alle Seiten mit dem Pfad, der benötigten Berechtigung und ob diese im Slave aufgerufen werden können, definiert.
 */
const routes = [
    {
        path: '/',
        Component: <Tokens />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/create',
        Component: <CreateToken />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: PrivilegesCategories.API_Token_Create,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/:objectId',
        Component: <Token />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: PrivilegesCategories.API_Token,
        initialObjectContext: true,
        slaveAccessible: false,
    },
    {
        path: '/:objectId/edit',
        Component: <Token />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: PrivilegesCategories.API_Token_Edit,
        initialObjectContext: true,
        slaveAccessible: false,
    },
    {
        path: '/:objectId/rights/*',
        Component: <RightsRoutes />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: PrivilegesCategories.RightsView_Token,
        initialObjectContext: true,
        slaveAccessible: false,
    },
    {
        path: '/*',
        Component: <Navigate replace to="/" />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
];

/**
 * Diese Komponente definiert alle Seiten und überprüft ob der Benutzer
 * ausreichend Rechte besitzt, um diese Seite aufzurufen.
 * @returns {JSX.Element}
 * @constructor
 */
export const TokensRoutes = () => {
    return <ExtendedRoutes routes={routes} />;
};

export default TokensRoutes;
