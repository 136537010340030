const BASENAME = process.env.REACT_APP_BASENAME;

export async function getMetadata() {
    const response = await fetch(`${BASENAME}metadata.json`, {
        credentials: 'include',
        mode: 'cors',
        method: 'GET',
        cache: 'no-store',
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.ERROR || 'Could not fetch contact metadata.');
    }

    return data;
}
