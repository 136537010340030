import { Col, Placeholder, Row } from 'react-bootstrap';

const LoadingComponent = () => {
    return (
        <Row>
            {[1, 2].map((key) => (
                <Col xs={12} key={key}>
                    <Placeholder as="div" animation="wave">
                        <Placeholder className="loadingItem" />
                    </Placeholder>
                </Col>
            ))}
            {[4, 5, 6, 7].map((key) => (
                <Col xs={3} key={key}>
                    <Placeholder as="div" animation="wave">
                        <Placeholder className="loadingItem" />
                    </Placeholder>
                </Col>
            ))}
            <Col xs={12} key={8}>
                <Placeholder as="div" animation="wave">
                    <Placeholder className="loadingItem" />
                </Placeholder>
            </Col>
        </Row>
    );
};

export default LoadingComponent;
