const API_GROUP_URL = process.env.REACT_APP_API_GROUP_URL;

export async function getGroups() {
    const response = await fetch(`${API_GROUP_URL}`, {
        credentials: 'include',
        method: 'GET',
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.ERROR || 'Could not fetch groups data.');
    }

    return data;
}

export async function getGroup(payload) {
    const response = await fetch(`${API_GROUP_URL}/${payload.id}`, {
        credentials: 'include',
        method: 'GET',
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.ERROR || 'Could not fetch group data.');
    }

    return data;
}

export async function deleteGroup(payload) {
    const response = await fetch(`${API_GROUP_URL}/${payload.id}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'DELETE',
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete group.');
    }

    return null;
}

export async function addGroup(payload) {
    const response = await fetch(`${API_GROUP_URL}/`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(payload.group),
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create group.');
    }

    return data;
}

export async function updateGroup(payload) {
    const response = await fetch(`${API_GROUP_URL}/${payload.id}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'PATCH',
        body: JSON.stringify(payload.group),
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update group.');
    }

    return data;
}
