import React from 'react';

import { Col, ProgressBar, Row } from 'react-bootstrap';

const LoadingPage = () => {
    return (
        <div className="container-fluid h-100">
            <Row className="row h-100">
                <Col xl={5} lg={4} md={4} sm={3} xs={2}></Col>
                <Col
                    xl={2}
                    lg={4}
                    md={4}
                    sm={6}
                    xs={8}
                    className="align-self-center shadow shadow-lg p-5 text-center rounded-3 rounded border border-1 border-primary">
                    <ProgressBar animated>
                        <ProgressBar
                            animated
                            striped
                            variant="primary"
                            now={33}
                            key={1}
                        />
                        <ProgressBar
                            animated
                            striped
                            variant="info"
                            now={33}
                            key={2}
                        />
                        <ProgressBar
                            animated
                            striped
                            variant="warning"
                            now={34}
                            key={3}
                        />
                    </ProgressBar>
                    <h1 className="pt-2 mb-0">PWM</h1>
                    <p>Loading, please wait ...</p>
                    <ProgressBar animated>
                        <ProgressBar
                            animated
                            striped
                            variant="primary"
                            now={33}
                            key={1}
                        />
                        <ProgressBar
                            animated
                            striped
                            variant="info"
                            now={33}
                            key={2}
                        />
                        <ProgressBar
                            animated
                            striped
                            variant="warning"
                            now={34}
                            key={3}
                        />
                    </ProgressBar>
                </Col>
            </Row>
        </div>
    );
};

export default LoadingPage;
