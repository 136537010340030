import React from 'react';

import { Navigate } from 'react-router-dom';

import ExtendedRoutes from '../../../components/ExtendedRoutes/ExtendedRoutes';
import { PrivilegesCategories } from '../../../enum/PrivilegesCategories';

const Error = React.lazy(() => import('./Error'));
const Errors = React.lazy(() => import('./Errors'));
const CreateError = React.lazy(() => import('./CreateError'));
const EditError = React.lazy(() => import('./EditError'));
const Resolution = React.lazy(() => import('./Resolution'));
const Resolutions = React.lazy(() => import('./Resolutions'));
const EditResolution = React.lazy(() => import('./EditResolution'));
const CreateResolution = React.lazy(() => import('./CreateResolution'));
const Solution = React.lazy(() => import('./Solution'));
const Answer = React.lazy(() => import('./Answer'));
const Answers = React.lazy(() => import('./Answers'));
const EditAnswer = React.lazy(() => import('./EditAnswer'));
const CreateAnswer = React.lazy(() => import('./CreateAnswer'));

/**
 * In diesem Array werden alle Seiten mit dem Pfad, der benötigten Berechtigung und ob diese im Slave aufgerufen werden können, definiert.
 */
const routes = [
    {
        path: '/',
        Component: <Errors />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/create',
        Component: <CreateError />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/:errorId',
        Component: <Error />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/:errorId/edit',
        Component: <EditError />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/:errorId/solution',
        Component: <Solution />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/resolutions',
        Component: <Resolutions />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/resolutions/create',
        Component: <CreateResolution />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/resolutions/:resolutionId',
        Component: <Resolution />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/resolutions/:resolutionId/edit',
        Component: <EditResolution />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/resolutions/:resolutionId/answers',
        Component: <Answers />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/resolutions/:resolutionId/answers/:referenceId',
        Component: <Answer />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/resolutions/:resolutionId/answers/:referenceId/edit',
        Component: <EditAnswer />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/resolutions/:resolutionId/answers/create',
        Component: <CreateAnswer />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/*',
        Component: <Navigate replace to="/" />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
];

/**
 * Diese Komponente definiert alle Seiten und überprüft ob der Benutzer
 * ausreichend Rechte besitzt, um diese Seite aufzurufen.
 * @returns {JSX.Element}
 * @constructor
 */
export const ErrorsRoutes = () => {
    return <ExtendedRoutes routes={routes} />;
};

export default ErrorsRoutes;
