const API_USERPREF_URL = process.env.REACT_APP_API_USER_PREF_URL;

export async function updateUserPref(payload) {
    const response = await fetch(`${API_USERPREF_URL}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(payload.Data),
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update user pref data.');
    }

    return null;
}

export async function getUserPref() {
    const response = await fetch(`${API_USERPREF_URL}`, {
        credentials: 'include',
        method: 'GET',
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.ERROR || 'Could not fetch user pref data.');
    }

    return data;
}
