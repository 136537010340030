export const PrivilegesCategories = {
    Station: 'Station',
    Station_Create: 'Station_Create',
    Station_Delete: 'Station_Delete',
    Station_Edit: 'Station_Edit',
    Station_History: 'Station_History',
    Station_Notify: 'Station_Notify',
    Station_Notify_Edit: 'Station_Notify_Edit',
    Station_MTS: 'Station_MTS',
    Station_MTS_Admin: 'Station_MTS_Admin',
    Station_Comment: 'Station_Comment',
    Station_Comment_Conv: 'Station_Comment_Conv',
    Station_Comment_Edit: 'Station_Comment_Edit',
    Station_Comment_Admin: 'Station_Comment_Admin',
    Station_Connect: 'Station_Connect',
    Station_Weather: 'Station_Weather',
    Station_Weather_Forecast: 'Station_Weather_Forecast',
    Station_OpeningHours: 'Station_OpeningHours',
    Station_Logging: 'Station_Logging',
    StationData_GOOGLE: 'StationData_GOOGLE',
    StationData_GOOGLE_Edit: 'StationData_GOOGLE_Edit',
    StationData_METADATA: 'StationData_METADATA',
    StationData_METADATA_Edit: 'StationData_METADATA_Edit',
    StationData_KEYDATA: 'StationData_KEYDATA',
    StationData_KEYDATA_Edit: 'StationData_KEYDATA_Edit',
    Station_Webreporting: 'Station_Webreporting',
    Domain_Dashboard_Template: 'Domain_Dashboard_Template',
    Domain_Dashboard_Template_Crea: 'Domain_Dashboard_Template_Crea',
    Domain_Dashboard_Template_Dele: 'Domain_Dashboard_Template_Dele',
    Domain_Dashboard_Template_Edit: 'Domain_Dashboard_Template_Edit',
    Domain_Dashboard: 'Domain_Dashboard',
    Domain_Dashboard_Create: 'Domain_Dashboard_Create',
    Domain_Dashboard_Delete: 'Domain_Dashboard_Delete',
    Domain_Dashboard_Edit: 'Domain_Dashboard_Edit',
    Group_Dashboard_Template: 'Group_Dashboard_Template',
    Group_Dashboard_Template_Creat: 'Group_Dashboard_Template_Creat',
    Group_Dashboard_Template_Delet: 'Group_Dashboard_Template_Delet',
    Group_Dashboard_Template_Edit: 'Group_Dashboard_Template_Edit',
    Group_Dashboard: 'Group_Dashboard',
    Group_Dashboard_Create: 'Group_Dashboard_Create',
    Group_Dashboard_Delete: 'Group_Dashboard_Delete',
    Group_Dashboard_Edit: 'Group_Dashboard_Edit',
    StationCgUi: 'StationCgUi',
    StationVPN: 'StationVPN',
    Group: 'Group',
    Group_Edit: 'Group_Edit',
    Group_Create: 'Group_Create',
    Group_Delete: 'Group_Delete',
    User: 'User',
    User_Edit: 'User_Edit',
    User_Create: 'User_Create',
    User_Delete: 'User_Delete',
    Admin: 'Admin',
    PWM_Admin: 'PWM_Admin',
    PWM_Service: 'PWM_Service',
    PWM_Development: 'PWM_Development',
    PWM_ReadOnly: 'PWM_ReadOnly',
    Customer_User_Admin: 'Customer_User_Admin',
    Customer_Group_Admin: 'Customer_Group_Admin',
    Customer_Station_Admin: 'Customer_Station_Admin',
    Customer_Token_Admin: 'Customer_Token_Admin',
    RightsView_User: 'RightsView_User',
    RightsView_Station: 'RightsView_Station',
    RightsView_Group: 'RightsView_Group',
    RightsView_Domain: 'RightsView_Domain',
    RightsView_Token: 'RightsView_Domain',
    Domain_Edit: 'Domain_Edit',
    Domain: 'Domain',
    API_Token: 'API_Token',
    API_Token_Edit: 'API_Token_Edit',
    API_Token_Create: 'API_Token_Create',
    Jobs: 'Jobs',
    Jobs_Edit: 'Jobs_Edit',
    Domain_ObjRef: 'Domain_ObjRef',
    Domain_Template: 'Domain_Template',
};
