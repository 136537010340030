const API_USER_URL = process.env.REACT_APP_API_USER_URL;
const API_RIGHT_CHECK_URL = process.env.REACT_APP_API_RIGHT_CHECK_URL;

export async function getUserRoles(payload) {
    const response = await fetch(`${API_USER_URL}/${payload.id}/roles`, {
        credentials: 'include',
        method: 'GET',
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.ERROR || 'Could not fetch user roles data.');
    }

    return data;
}

export async function updateUserRoles(payload) {
    const response = await fetch(`${API_USER_URL}/${payload.id}/roles`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(payload.data),
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.ERROR || 'Could not patch user roles data.');
    }

    return data;
}

export async function getUserRightCheckRoles() {
    const response = await fetch(`${API_RIGHT_CHECK_URL}/roleoverview`, {
        credentials: 'include',
        method: 'GET',
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(
            data.ERROR || 'Could not fetch user right check roles data.'
        );
    }

    return data;
}
