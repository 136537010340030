import React from 'react';

import { Navigate } from 'react-router-dom';

import ExtendedRoutes from '../../../components/ExtendedRoutes/ExtendedRoutes';
import { PrivilegesCategories } from '../../../enum/PrivilegesCategories';

const CustomTemplateEdit = React.lazy(() => import('./CustomTemplateEdit'));
const CustomTemplate = React.lazy(() => import('./CustomTemplate'));
const PredefinedTemplate = React.lazy(() => import('./PredefinedTemplate'));
const ObjectReference = React.lazy(() => import('./ObjectReference'));
const RightsStructure = React.lazy(() => import('./RightsStructure'));
const TargetAdd = React.lazy(() => import('./TargetAdd'));
const Template = React.lazy(() => import('./Template'));
const Members = React.lazy(() => import('./Members'));
const Targets = React.lazy(() => import('./Targets'));
const Analyse = React.lazy(() => import('./Analyse'));
const Target = React.lazy(() => import('./Target'));
const Roles = React.lazy(() => import('./Roles'));
const Home = React.lazy(() => import('./Home'));
const PredefinedTemplateEdit = React.lazy(
    () => import('./PredefinedTemplateEdit')
);

/**
 * In diesem Array werden alle Seiten mit dem Pfad, der benötigten Berechtigung und ob diese im Slave aufgerufen werden können, definiert.
 */
const routes = [
    {
        path: '/',
        Component: <Home />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/roles',
        Component: <Roles />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: true,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/rights-structure',
        Component: <RightsStructure />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/object-reference',
        Component: <ObjectReference />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: [
            PrivilegesCategories.Domain_Edit,
            PrivilegesCategories.User_Edit,
            PrivilegesCategories.Station_Edit,
            PrivilegesCategories.Group_Edit,
        ],
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/members',
        Component: <Members />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: [
            PrivilegesCategories.RightsView_Group,
            PrivilegesCategories.RightsView_User,
            PrivilegesCategories.RightsView_Station,
            PrivilegesCategories.RightsView_Domain,
            PrivilegesCategories.RightsView_Token,
        ],
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/targets',
        Component: <Targets />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: [
            PrivilegesCategories.RightsView_Group,
            PrivilegesCategories.RightsView_User,
            PrivilegesCategories.RightsView_Token,
        ],
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/targets/add',
        Component: <TargetAdd />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: [
            PrivilegesCategories.User_Edit,
            PrivilegesCategories.Station_Edit,
            PrivilegesCategories.Group_Edit,
            PrivilegesCategories.API_Token_Edit,
        ],
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/targets/:targetId',
        Component: <Target />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: [
            PrivilegesCategories.User_Edit,
            PrivilegesCategories.Station_Edit,
            PrivilegesCategories.Group_Edit,
            PrivilegesCategories.API_Token_Edit,
        ],
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/targets/:targetId/analyse',
        Component: <Analyse />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: true,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/template',
        Component: <Template />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: [PrivilegesCategories.Domain_Template],
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/template/custom',
        Component: <CustomTemplate />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: true,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/template/custom/edit',
        Component: <CustomTemplateEdit />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: true,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/template/predefined',
        Component: <PredefinedTemplate />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: [PrivilegesCategories.Domain_Template],
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/template/predefined/edit',
        Component: <PredefinedTemplateEdit />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: [PrivilegesCategories.Domain_Template],
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/*',
        Component: <Navigate replace to="/" />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
];

/**
 * Diese Komponente definiert alle Seiten und überprüft ob der Benutzer
 * ausreichend Rechte besitzt, um diese Seite aufzurufen.
 * @returns {JSX.Element}
 * @constructor
 */
export const RightsRoutes = () => {
    return <ExtendedRoutes routes={routes} />;
};

export default RightsRoutes;
