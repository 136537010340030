import React, { useContext } from 'react';

import { faTimesSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import AppContext from '../../../context/app-context';

const CloseButton = (props) => {
    const { t } = useTranslation('', { keyPrefix: 'buttons' });
    const { closeHandler, className, hidden, disabled, outline, size } = props;
    const appCtx = useContext(AppContext);
    const { isDarkMode } = appCtx;
    return (
        <Button
            name="close"
            size={size}
            className={className}
            hidden={hidden}
            disabled={disabled}
            variant={isDarkMode || outline ? 'outline-light' : 'dark'}
            onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimesSquare} className="me-2" />
            {t('close')}
        </Button>
    );
};

CloseButton.propTypes = {
    closeHandler: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    outline: PropTypes.bool,
    hidden: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'lg', null]),
};

CloseButton.defaultProps = {
    className: '',
    disabled: false,
    outline: false,
    hidden: false,
    size: null,
};

export default CloseButton;
