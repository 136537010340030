import React from 'react';

import { Navigate } from 'react-router-dom';

import ExtendedRoutes from '../../../components/ExtendedRoutes/ExtendedRoutes';
import { PrivilegesCategories } from '../../../enum/PrivilegesCategories';
import { RightsRoutes } from '../../rights';

const CreateStation = React.lazy(() => import('./CreateStation'));
const OpeningHours = React.lazy(() => import('./OpeningHours'));
const EditStation = React.lazy(() => import('./EditStation'));
const Connection = React.lazy(() => import('./Connection'));
const Stations = React.lazy(() => import('./Stations'));
const Station = React.lazy(() => import('./Station'));

/**
 * In diesem Array werden alle Seiten mit dem Pfad, der benötigten Berechtigung und ob diese im Slave aufgerufen werden können, definiert.
 */
const routes = [
    {
        path: '/',
        Component: <Stations />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/create',
        Component: <CreateStation />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: PrivilegesCategories.Station_Create,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/:objectId/rights/*',
        Component: <RightsRoutes />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: PrivilegesCategories.RightsView_Station,
        initialObjectContext: true,
        slaveAccessible: true,
    },
    {
        path: '/:objectId',
        Component: <Station />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: PrivilegesCategories.Station,
        initialObjectContext: true,
        slaveAccessible: true,
    },
    {
        path: '/:objectId/opening-hours',
        Component: <OpeningHours />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: PrivilegesCategories.Station_OpeningHours,
        initialObjectContext: true,
        slaveAccessible: true,
    },
    {
        path: '/:objectId/edit',
        Component: <EditStation />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: PrivilegesCategories.Station_Edit,
        initialObjectContext: true,
        slaveAccessible: true,
    },
    {
        path: '/:objectId/connection',
        Component: <Connection />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: PrivilegesCategories.Station_Connect,
        initialObjectContext: true,
        slaveAccessible: true,
    },
    {
        path: '/*',
        Component: <Navigate replace to="/" />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
];

/**
 * Diese Komponente definiert alle Seiten und überprüft ob der Benutzer
 * ausreichend Rechte besitzt, um diese Seite aufzurufen.
 * @returns {JSX.Element}
 * @constructor
 */
export const StationsRoutes = () => {
    return <ExtendedRoutes routes={routes} />;
};

export default StationsRoutes;
