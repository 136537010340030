import { useState } from 'react';

import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Toast } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import ShowButton from '../Buttons/General/ShowButton';

const ToastElement = (props) => {
    const [show, setShow] = useState(true);
    const navigate = useNavigate();
    const { variant, title, description, to } = props;
    return (
        <Toast
            key={Math.floor(Math.random() * 100)}
            onClose={() => setShow(false)}
            show={show}
            delay={10000}
            autohide
            bg={variant}>
            <Toast.Header>
                <FontAwesomeIcon icon={faBell} />
                <strong className="me-auto ms-2">{title}</strong>
            </Toast.Header>
            <Toast.Body
                className={variant === 'light' ? 'text-dark' : 'text-white'}>
                {description}
                {to && (
                    <ShowButton
                        size="sm"
                        className="w-100 mt-2"
                        showHandler={() => navigate(to)}
                    />
                )}
            </Toast.Body>
        </Toast>
    );
};

ToastElement.propTypes = {
    variant: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    to: PropTypes.string,
};

ToastElement.defaultProps = {
    variant: 'light',
    title: '',
    description: '',
    to: null,
};

export default ToastElement;
