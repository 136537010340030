import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { getDomains } from '../api/api-domains';
import { getGroups } from '../api/api-group';
import { checkRights } from '../api/api-rights';
import { getUserRightCheckRoles } from '../api/api-user';
import { getUserPref } from '../api/api-userpref';
import LoadingPage from '../components/UI/LoadingPage';
import TechnicalIssues from '../components/UI/TechnicalIssues';
import { ApiStatusCategories } from '../enum/ApiStatusCategories';
import useHttp from '../hooks/useHttp';

const UserContext = React.createContext({
    username: '',
    timezone: 'UTC',
    dateFormat: 'YYYY-MM-DD',
    timeFormat: 'HH:mm:ss',
    combinedDateTimeFormat: 'YYYY-MM-DD - HH:mm:ss',
    language: 'en',
    userId: '',
    domainId: '',
    isAdmin: false,
    groups: [],
    domains: [],
    hasRoles: () => null,
    reloadUserContext: () => null,
    loadDomains: () => null,
    loadGroups: () => null,
    statusDomainsRequest: ApiStatusCategories.PENDING,
    statusGroupsRequest: ApiStatusCategories.PENDING,
    timestampDomains: null,
    timestampGroups: null,
});

export const UserContextProvider = (props) => {
    const { i18n } = useTranslation();
    const [groups, setGroups] = useState([]);
    const [domains, setDomains] = useState([]);
    const { children } = props;
    const {
        sendRequest: sendUserRequest,
        status: statusUserRequest,
        data: userData,
    } = useHttp(checkRights, true);
    const {
        sendRequest: sendUserPrefRequest,
        status: statusUserPrefRequest,
        data: userPrefData,
    } = useHttp(getUserPref);
    const {
        sendRequest: sendGroupsRequest,
        status: statusGroupsRequest,
        data: groupsData,
        timestamp: timestampGroups,
    } = useHttp(getGroups);
    const {
        sendRequest: sendDomainsRequest,
        status: statusDomainsRequest,
        data: domainsData,
        timestamp: timestampDomains,
    } = useHttp(getDomains);
    const {
        sendRequest: sendUserRightCheckRolesRequest,
        status: statusUserRightCheckRolesRequest,
        data: userRightCheckRolesData,
    } = useHttp(getUserRightCheckRoles);

    const reloadUserContext = useCallback(() => {
        sendUserPrefRequest();
        sendUserRightCheckRolesRequest();
    }, [sendUserPrefRequest, sendUserRightCheckRolesRequest]);

    /**
     * Überprüft die Rechte, die der Benutzer auf die ausgewählte Gruppe hat.
     * Wenn der Benutzer keine Gruppe ausgewählt hat, werden die Rechte auf
     * die Domain geprüft.
     * @type {(...args: any[]) => any}
     */
    const hasRoles = useCallback(
        (categories) => {
            if ('RoleRights' in (userRightCheckRolesData ?? {})) {
                if (Array.isArray(categories)) {
                    for (let category of categories) {
                        if (
                            userRightCheckRolesData['RoleRights'][category] ??
                            false
                        ) {
                            return true;
                        }
                    }
                } else {
                    return (
                        userRightCheckRolesData['RoleRights'][categories] ??
                        false
                    );
                }
            }
            return false;
        },
        [userRightCheckRolesData]
    );

    const loadDomains = useCallback(() => {
        sendDomainsRequest();
    }, [sendDomainsRequest]);

    const loadGroups = useCallback(() => {
        sendGroupsRequest();
    }, [sendGroupsRequest]);

    useEffect(() => {
        sendUserRequest();
    }, [sendUserRequest]);

    useEffect(() => {
        if (
            statusUserRequest === ApiStatusCategories.COMPLETED &&
            statusUserRightCheckRolesRequest === ApiStatusCategories.NULL
        ) {
            sendUserRightCheckRolesRequest();
        }
        if (
            statusUserRequest === ApiStatusCategories.COMPLETED &&
            statusUserPrefRequest === ApiStatusCategories.NULL
        ) {
            sendUserPrefRequest();
        }
        if (
            statusUserRequest === ApiStatusCategories.COMPLETED &&
            (statusUserPrefRequest === ApiStatusCategories.COMPLETED ||
                statusUserPrefRequest === ApiStatusCategories.FAILED)
        ) {
            i18n.changeLanguage(userPrefData?.Lang.toLowerCase() ?? 'en');
            loadDomains();
            loadGroups();
        }
    }, [
        statusUserRightCheckRolesRequest,
        sendUserRightCheckRolesRequest,
        statusUserPrefRequest,
        sendUserPrefRequest,
        statusUserRequest,
        userPrefData,
        loadDomains,
        loadGroups,
        userData,
        i18n,
    ]);

    useEffect(() => {
        if (statusGroupsRequest === ApiStatusCategories.COMPLETED) {
            setGroups(
                groupsData?.Groups.sort((a, b) =>
                    a.GroupName.localeCompare(b.GroupName)
                ) ?? []
            );
        }
    }, [statusGroupsRequest, groupsData, setGroups]);

    useEffect(() => {
        if (statusDomainsRequest === ApiStatusCategories.COMPLETED) {
            setDomains(
                domainsData?.Domains.sort((a, b) =>
                    a.DomainName.localeCompare(b.DomainName)
                ) ?? []
            );
        }
    }, [statusDomainsRequest, domainsData, setDomains]);

    return (
        <UserContext.Provider
            value={{
                username: userData?.UserName ?? '',
                timezone: userPrefData?.TimeZone ?? 'UTC',
                dateFormat: userPrefData?.DateFormat ?? 'YYYY-MM-DD',
                timeFormat: userPrefData?.TimeFormat ?? 'HH:mm:ss',
                combinedDateTimeFormat:
                    (userPrefData?.DateFormat ?? 'YYYY-MM-DD') +
                    ' - ' +
                    (userPrefData?.TimeFormat ?? 'HH:mm:ss'),
                language: userPrefData?.Lang.toLowerCase() ?? 'en',
                userId: userData?.ID_User ?? '',
                domainId: userData?.ID_Domain ?? '',
                isAdmin: userData?.IsAdmin ?? false,
                groups: groups,
                domains: domains,
                hasRoles,
                reloadUserContext,
                loadDomains: loadDomains,
                loadGroups: loadGroups,
                statusDomainsRequest: statusDomainsRequest,
                statusGroupsRequest: statusGroupsRequest,
                timestampDomains: timestampDomains,
                timestampGroups: timestampGroups,
            }}>
            {statusUserRequest === ApiStatusCategories.COMPLETED &&
                (statusUserPrefRequest === ApiStatusCategories.COMPLETED ||
                    statusUserPrefRequest === ApiStatusCategories.FAILED) &&
                (statusGroupsRequest === ApiStatusCategories.COMPLETED ||
                    statusGroupsRequest === ApiStatusCategories.FAILED ||
                    groups.length > 0) &&
                (statusDomainsRequest === ApiStatusCategories.COMPLETED ||
                    statusDomainsRequest === ApiStatusCategories.FAILED ||
                    domains.length > 0) &&
                children}
            {(statusUserRequest === ApiStatusCategories.PENDING ||
                statusUserPrefRequest === ApiStatusCategories.PENDING ||
                (statusGroupsRequest === ApiStatusCategories.PENDING &&
                    groups.length === 0) ||
                (statusDomainsRequest === ApiStatusCategories.PENDING &&
                    domains.length === 0)) && <LoadingPage />}
            {statusUserRequest === ApiStatusCategories.FAILED && (
                <TechnicalIssues />
            )}
        </UserContext.Provider>
    );
};

export default UserContext;
