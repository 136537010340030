import React, { useContext } from 'react';

import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import AppContext from '../../../context/app-context';

const UpgradeNavLink = (props) => {
    const { bsPrefix, disabled, hidden, className, text } = props;
    const { t } = useTranslation('', { keyPrefix: 'upgrade' });
    const ctxApp = useContext(AppContext);
    const { addAlert } = ctxApp;

    const toggleShow = () => {
        addAlert({
            title: t('title'),
            description: t('description'),
            variant: 'danger',
            to: '/service-packages',
        });
    };

    return (
        <Nav.Link
            name="upgrade"
            disabled={disabled}
            hidden={hidden}
            bsPrefix={bsPrefix}
            className={className + ` text-secondary text-nowrap`}
            onClick={toggleShow}>
            <FontAwesomeIcon
                icon={faLock}
                className={text !== '' ? 'me-2' : ''}
            />
            {text}
        </Nav.Link>
    );
};

UpgradeNavLink.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    bsPrefix: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
};

UpgradeNavLink.defaultProps = {
    text: '',
    className: '',
    bsPrefix: '',
    disabled: false,
    hidden: false,
};

export default UpgradeNavLink;
