import React, { useContext } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import AppContext from '../../../context/app-context';
import UserContext from '../../../context/user-context';
import ViewContext from '../../../context/view-context';
import { PrivilegesCategories } from '../../../enum/PrivilegesCategories';

const Administration = React.lazy(() => import('./Administration'));
const ObjectReferences = React.lazy(() => import('./ObjectReferences'));
const TemplateDomains = React.lazy(() => import('./TemplateDomains'));
const CreateTemplate = React.lazy(() => import('./CreateTemplate'));
const EditTemplate = React.lazy(() => import('./TemplateEdit'));
const Rights = React.lazy(() => import('./Rights'));
const Templates = React.lazy(() => import('./Templates'));
const Template = React.lazy(() => import('./Template'));
const MassData = React.lazy(() => import('./MassData'));
const SQS = React.lazy(() => import('./SQS'));

/**
 * In diesem Array werden alle Seiten mit dem Pfad, der benötigten Berechtigung und ob diese im Slave aufgerufen werden können, definiert.
 */
const routes = [
    {
        path: '/',
        Component: <Administration />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: PrivilegesCategories.PWM_Admin,
        needsAdminPrivilege: true,
        slaveAccessible: false,
    },
    {
        path: '/sqs',
        Component: <SQS />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: PrivilegesCategories.PWM_Admin,
        needsAdminPrivilege: true,
        slaveAccessible: false,
    },
    {
        path: '/rights',
        Component: <Rights />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: PrivilegesCategories.PWM_Admin,
        needsAdminPrivilege: true,
        slaveAccessible: false,
    },
    {
        path: '/mass-data',
        Component: <MassData />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: PrivilegesCategories.PWM_Admin,
        needsAdminPrivilege: true,
        slaveAccessible: false,
    },
    {
        path: '/object-references',
        Component: <ObjectReferences />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: PrivilegesCategories.PWM_Admin,
        needsAdminPrivilege: true,
        slaveAccessible: false,
    },
    {
        path: '/templates',
        Component: <Templates />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: PrivilegesCategories.PWM_Admin,
        needsAdminPrivilege: true,
        slaveAccessible: false,
    },
    {
        path: '/templates/create',
        Component: <CreateTemplate />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: PrivilegesCategories.PWM_Admin,
        needsAdminPrivilege: true,
        slaveAccessible: false,
    },
    {
        path: '/templates/:templateId',
        Component: <Template />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: PrivilegesCategories.PWM_Admin,
        needsAdminPrivilege: true,
        slaveAccessible: false,
    },
    {
        path: '/templates/:templateId/edit',
        Component: <EditTemplate />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: PrivilegesCategories.PWM_Admin,
        needsAdminPrivilege: true,
        slaveAccessible: false,
    },
    {
        path: '/templates/:templateId/domains',
        Component: <TemplateDomains />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: PrivilegesCategories.PWM_Admin,
        needsAdminPrivilege: true,
        slaveAccessible: false,
    },
    {
        path: '/*',
        Component: <Navigate replace to="/" />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        slaveAccessible: false,
    },
];

/**
 * Diese Komponente definiert alle Seiten und überprüft ob der Benutzer
 * ausreichend Rechte besitzt, um diese Seite aufzurufen.
 * @returns {JSX.Element}
 * @constructor
 */
export const AdministrationRoutes = () => {
    const groupCtx = useContext(ViewContext);
    const { hasGroupPrivilege, hasDomainPrivilege } = groupCtx;
    const appCtx = useContext(AppContext);
    const { isSlave } = appCtx;
    const userCtx = useContext(UserContext);
    const { isAdmin } = userCtx;

    return (
        <Routes>
            {routes.map(
                ({
                    path,
                    Component,
                    needsGroupPrivilege,
                    needsDomainPrivilege,
                    needsAdminPrivilege,
                    slaveAccessible,
                }) => {
                    if (!isSlave || (isSlave && slaveAccessible)) {
                        /**
                         * In dieser If-Abfrage werden alle Rechte überprüft, wenn welche gefordert werden.
                         */
                        if (
                            (needsGroupPrivilege === undefined ||
                                hasGroupPrivilege(needsGroupPrivilege)) &&
                            (needsDomainPrivilege === undefined ||
                                hasDomainPrivilege(needsDomainPrivilege)) &&
                            (needsAdminPrivilege === undefined ||
                                needsAdminPrivilege === isAdmin)
                        ) {
                            return (
                                <Route
                                    key={path}
                                    exact
                                    path={path}
                                    element={Component}
                                />
                            );
                        }
                    }
                }
            )}
        </Routes>
    );
};

export default AdministrationRoutes;
